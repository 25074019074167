import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  Chip,
  TextField
} from '@mui/material';

import "./ProductList.css"

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const ProductList = ({ products, addToCart, removeFromCart }) => {
  const productsPerPage = 12;
  const [currentPage, setCurrentPage] = useState(1);
  const [quantities, setQuantities] = useState({});
  const [addedToCart, setAddedToCart] = useState({});
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedColors, setSelectedColors] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const handleColorChange = (productId, selectedColor) => {
    setSelectedColors((prevColors) => ({
      ...prevColors,
      [productId]: selectedColor,
    }));
  };

  const handleQuantityChange = (productId, newQuantity) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: newQuantity,
    }));

    // Update shopping cart notification number when the quantity changes
    const totalQuantity = Object.values(quantities).reduce((total, qty) => total + qty, 0);
    setAddedToCart({ totalQuantity });
  };

  const handleAddToCart = (productId) => {
    const product = products.find((item) => item.id === productId);
  
    if (!product) {
      console.error(`Product with id ${productId} not found.`);
      return;
    }
  
    if (product.colors && product.colors.length > 0) {
      // Product has color options
      if (!selectedColors[productId]) {
        setErrorMessage('Please select a color before adding to cart.');
        return;
      }
    }
  
    // Clear any existing error message
    setErrorMessage('');
  
    addToCart(productId, selectedColors[productId]);
    setAddedToCart({ totalQuantity: 1 });
  
    // Increase the quantity by 1 or set to 1 if it doesn't exist
    const newQuantity = (quantities[productId] || 0) + 1;
    handleQuantityChange(productId, newQuantity);
  };
  

  const handleRemoveFromCart = (productId) => {
    removeFromCart(productId);

    // Decrease the quantity by 1 or set to 0 if it goes below 0
    const newQuantity = Math.max((quantities[productId] || 0) - 1, 0);
    handleQuantityChange(productId, newQuantity);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setShowBackToTop(scrollY > 400); // Adjust the scroll threshold as needed
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentPage]);

  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div style={{minHeight:"68vh"}}>
      <div className="search-container" style={{ marginBottom: '20px' }}>
        <TextField
          placeholder='Search Products'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className='search-field'
        />
      </div>
{/* Display error message */}
{errorMessage && (
        <div style={{ color: 'red', marginTop: '10px' }}>
          {errorMessage}
        </div>
      )}
    <div className="product-list" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', fontFamily: "Graphik-Regular"}}>
      {showBackToTop && (
        <IconButton
          onClick={handleBackToTop}
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            backgroundColor: '#3f51b5',
            color: '#fff',
            zIndex: 1000,
          }}
        >
          <KeyboardArrowUpIcon />
        </IconButton>
      )}
      
      {currentProducts.map((product) => (
        <Card key={product.id} className='product-card'>
          <Link to={`/products/${product.id}`} style={{ color: "#000", textDecoration: "none" }}>
            <CardMedia
              component="div"
            >
              <img
                alt={product.name}
                className="product-image"
                loading="lazy"
                style={{
                  maxWidth: "250px",
                  minWidth: "250px",
                  height: "auto",
                  display: "flex",
                  margin: "0 auto",
                  padding: "8px",
                  borderRadius: "20px",
                  maxHeight: "250px",
                  width: "100%",
                }}
                src={product.image} />

            </CardMedia>
            </Link>
            <CardContent>
              <Typography className='product-name' gutterBottom  style={{ fontFamily: 'Graphik, Calibre-Regular, Segoe UI, Helvetica Neue, sans-serif', paddingBottom: '10px' }}>
                {product.name}
              </Typography>
              <Typography color="text.secondary"  style={{ fontFamily: 'Graphik, Calibre-Regular, Segoe UI, Helvetica Neue, sans-serif' }}>
                &#x20A6;{product.price}
              </Typography>
              {product.colors && product.colors.length > 0 && (
                <div>
                  <label htmlFor={`colorDropdown_${product.id}`} style={{ fontFamily: 'Graphik, Calibre-Regular, Segoe UI, Helvetica Neue, sans-serif', marginTop: '10px' }}>Color: </label>
                  <select
                    id={`colorDropdown_${product.id}`}
                    onChange={(e) => handleColorChange(product.id, e.target.value)}
                    value={selectedColors[product.id] || ''}
                    style={{ fontFamily: 'Graphik, Calibre-Regular, Segoe UI, Helvetica Neue, sans-serif', 
                    marginLeft: '5px',
                    padding: '5px',
                    backgroundColor: '#fff',  // Background color of the dropdown
                    color: '#000',            // Text color of the dropdown
                    border: '1px solid #ccc', // Border color of the dropdown
                    borderRadius: '5px', }}
                  >
                    <option value="" disabled style={{ fontFamily: 'Graphik, Calibre-Regular, Segoe UI, Helvetica Neue, sans-serif'}}> Select Color</option>
                    {product.colors.map((color) => (
                      <option key={color} value={color} style={{ backgroundColor: '#fff', color: '#000', fontFamily: 'Graphik, Calibre-Regular, Segoe UI, Helvetica Neue, sans-serif' }}>
                        {color}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </CardContent>
          
          <div className="quantity-controls">
            {quantities[product.id] > 0 ? (
              <>
                <IconButton
                  onClick={() => handleRemoveFromCart(product.id)}
                  aria-label="RemoveCircleIcon"
                  size="medium"
                >
                  <RemoveCircleIcon fontSize="inherit" />
                </IconButton>
                <Chip
                  size="small"
                  variant="outlined"
                 
                  label={quantities[product.id]}
                  style={{ fontFamily: 'Graphik, Calibre-Regular, Segoe UI, Helvetica Neue, sans-serif' }}
                />
                <IconButton
                  onClick={() => handleAddToCart(product.id)}
                  aria-label="AddCircleIcon"
                  size="medium"
                >
                  <AddCircleIcon fontSize="inherit" />
                </IconButton>
              </>
            ) : (
            
            <button onClick={() => handleAddToCart(product.id, selectedColors[product.id])} 
            className='addToCartButton' style={{ fontFamily: 'Graphik, Calibre-Regular, Segoe UI, Helvetica Neue, sans-serif',
            cursor: 'pointer' }} >
              Add to Cart
          </button>
           )}
           
          </div>
           
        </Card>
      ))}
    </div>

     {/* Pagination buttons */}
     <div style={{ margin: '20px' }}>
     {currentPage > 1 && (
        <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1} className='prevBtn' style={{borderRadius: '5px',padding: '2px', fontFamily: 'Graphik, Calibre-Regular, Segoe UI, Helvetica Neue, sans-serif' }}>
          Previous Page
        </button>
     )}
        <span style={{ margin: '0 10px', fontFamily: 'Graphik, Calibre-Regular, Segoe UI, Helvetica Neue, sans-serif' }}>{currentPage}</span>
        {indexOfLastProduct < filteredProducts.length && (  
        <button onClick={() => setCurrentPage(currentPage + 1)} disabled={indexOfLastProduct >= products.length} className='nextBtn' style={{borderRadius: '5px',padding: '2px', fontFamily: 'Graphik, Calibre-Regular, Segoe UI, Helvetica Neue, sans-serif' }}>
          Next Page
        </button>
        )}
      </div>
    </div>
  );
};

export default ProductList;
