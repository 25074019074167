
import Chrome_1000 from './assests/images/Chrome_1000.jpg';
import Dapping_Dish_500 from './assests/images/Dapping_Dish_500.jpg';
import Dust_Off_Brush_1200 from './assests/images/Dust_Off_Brush_1200.jpg';
import Glitters_300 from './assests/images/Glitters_300.jpg';
import Glitters_300_Green from './assests/images/Glitters_300_Green.jpg';
import Glitters_300_mix from './assests/images/Glitters_300_mix.jpg';
import Kolinsky_Brush_2500 from './assests/images/Kolinsky_Brush_2500.jpg';
import Nails_Buffer_250 from './assests/images/Nails_Buffer_250.jpg';
import Nails_Cutter_1500 from './assests/images/Nails_Cutter_1500.jpg';
import Nails_File_200 from './assests/images/Nails_File_200.jpg';
import Spider_Gel_1500 from './assests/images/Spider_Gel_1500.jpg';
import Stone_Picker_700 from './assests/images/Stone_Picker_700.jpg';
import base_coat_1400 from './assests/images/base_coat_1400.jpg';
import dotting_tools_1500 from './assests/images/dotting_tools_1500.jpg';
import Reflective_chrome_1500 from './assests/images/Reflective_chrome_1500.jpg';
import nails_stickers_1500 from './assests/images/nails_stckers_1500.jpg';
import red_gel_1400 from './assests/images/red_gel_1400.jpg';
import acrylic_powder_1000 from './assests/images/acrylic_powder_1000.jpg';
import cuticle_nipper_1500 from './assests/images/cuticle_nipper_1500.jpg';
import stones_1500 from './assests/images/stones_1500.jpg';
import toe_separator_2500 from './assests/images/toe_separator_2500.jpg';
import toe_nails_3500 from './assests/images/toe_nails_3500.jpg';
import kaws_charm_3000 from './assests/images/kaws_charm_3000.jpg';
import nails_box_15000 from './assests/images/nails_box_15000.jpg';
import top_coat_1400 from './assests/images/top_coat_1400.jpg';
import snake_charm_500 from './assests/images/snake_charm_500.jpg';
import almond_nails_4500 from './assests/images/almond_nails_4500.jpg';
import Stiletto_Nails_4500 from './assests/images/Stiletto_Nails_4500.jpg';
import Coffin_Nails_4500 from './assests/images/Coffin_Nails_4500.jpg';
import Box_Charm_3000 from './assests/images/Box_Charm_3000.jpg';
import Blooming_Gel_2000 from './assests/images/Blooming_Gel_2000.jpg';
import Cross_Charm_200 from './assests/images/Cross_Charm_200.jpg';
import Butterfly_Charm_200 from './assests/images/Butterfly_Charm_200.jpg';
import Teddy_Bear_167 from './assests/images/Teddy_Bear_167.jpg';
import Kaws_Charm_200 from './assests/images/Kaws_Charm_200.jpg';
import Teddybear_Charm_200 from './assests/images/Teddybear_Charm_200.jpg';
import LongSquare_Nails_400 from './assests/images/LongSquare_Nails_400.jpg';
import Arm_Rest_6000 from './assests/images/Arm_Rest_6000.jpg';
import Arm_Rest_10000 from './assests/images/Arm_Rest_10000.jpg';
import Cardib_Nails_400 from './assests/images/Cardib_Nails_400.jpg';
import Dissolver_2500 from './assests/images/Dissolver_2500.jpg';
import Practice_Hand_14000 from './assests/images/Practice_Hand_14000.jpg';
import Nails_Tape_1500 from './assests/images/Nails_Tape_1500.jpg';
import ShortSquare_Nails_300 from './assests/images/ShortSquare_Nails_300.jpg';
import Stone_1500 from './assests/images/stones_1500.jpg';
import Flower_Charm_1500 from './assests/images/Flower_Charm_1500.jpg';
import LongSquare_NailsPack_5000 from './assests/images/LongSquare_NailsPack_5000.jpg';
import FullCoverSquare_Tips_5000 from './assests/images/FullCoverSquare_Tips_5000.jpg';
import Monomer_Cup_500 from './assests/images/Monomer_Cup_500.jpg';
import Nail_Stand_1500 from './assests/images/Nails_Tape_1500.jpg';
import Art_Pen_1500 from './assests/images/Art_Pen_1500.jpg';
import ThreeD_FlowerBrush_1500 from './assests/images/ThreeD_FlowerBrush_1500.jpg';
import Small_Monomer_1000 from './assests/images/Small_Monomer_1000.jpg';
import Clear_Powder_1000 from './assests/images/Clear_Powder_1000.jpg';
import Kolinsky_Brushed_3500 from './assests/images/Kolinsky_Brushed_3500.jpg';
import HalfTip_Nails_5500 from './assests/images/HalfTip_Nails_5500.jpg';
import Pigment_1500 from './assests/images/Pigment_1500.jpg';
import Gold_Silver_Liner_1500 from './assests/images/Gold_Silver_Liner_1500.jpg';
import Medium_Liquid_7000 from './assests/images/Medium_Liquid_7000.jpg';
import Powder_Brush_1500 from './assests/images/Powder_Brush_1500.jpg';
import Foil_1800 from './assests/images/Foil_1800.jpg';
import Acetone_3500 from './assests/images/Acetone_3500.jpg';
import Mixing_Plate_500 from './assests/images/Mixing_Plate_500.jpg';
import RhineStone_Glue_5000 from './assests/images/RhineStone_Glue_5000.jpg';
import PressOn_Glue_1500 from './assests/images/PressOn_Glue_1500.jpg';
import Tack_It_2500 from './assests/images/Tack_It_2500.png';
import Solid_gel_1500 from './assests/images/Solid_gel_1500.jpg';
import Air_Brush_23000 from './assests/images/Air_Brush_23000.jpg';
import Dissolver_Cup_700 from './assests/images/Dissolver_Cup_700.jpg';
import Stone_Glue_1500 from './assests/images/Stone_Glue_1500.jpg';
import Primer_1500 from './assests/images/Primer_1500.jpg';
import Practice_Hand_4000 from './assests/images/Practice_Hand_4000.jpg';
import fur_rug_5000 from './assests/images/fur_rug_5000.jpg';
import nonrechargable_drill_13000 from './assests/images/nonrechargable_drill_13000.jpg';
import nails_magnet_2000 from './assests/images/nails_magnet_2000.jpg';
import cuticle_remover_150 from './assests/images/cuticle_remover_150.jpg';
import cuticle_oil_500 from './assests/images/cuticle_oil_500.jpg';
import dotting_pen_1500 from './assests/images/dotting_pen_1500.jpg';
import sugar_500 from './assests/images/sugar_500.jpg';
import nonrechargable_uvlamp_13000 from './assests/images/nonrechargable_uvlamp_13000.jpg';
import Rechargeable_UVLamp_23000 from './assests/images/Rechargeable_UVLamp_23000.jpg';
import Acrylic_Cup_6000 from './assests/images/Acrylic_Cup_6000.jpg';
import PressOn_Glue_150 from './assests/images/PressOn_Glue_150.jpg';

const products = [
  {
    id: 1,
    name: 'Chrome',
    price: 1000,
    quantity: 1,
    image: Chrome_1000,
    colors: ['Red', 'Green', 'Blue', 'Pink', 'Gold', 'Silver'],
    description: 'Nail Powder | Metallic Chrome Mirror Powder with applicator',
    category: 'Nail Powder'
  },
  {
    id: 2,
    name: 'Dapping Dish',
    price: 1000,
    quantity: 10,
    image: Dapping_Dish_500,
    description: 'High quality.This dappen dish is cute and handy. This dappen dish can be used for mixing all acrylic liquid and powder.Suitable for professional, nail salon, nail art school or college, nail art artist and personal or home use.',
    category: 'Nail Tools'
  },
  {
    id: 3,
    name: 'Dust Off Brush',
    price: 2000,
    quantity: 10,
    image: Dust_Off_Brush_1200,
    description: 'Special Soft Bristle Hairdressing Brush Childrens Hair Sweeping Cleaning Brush Broken Hair Brush Barber Accessories',
    category: 'Brush'
  },
  {
    id: 4,
    name: 'Glitters',
    price: 300,
    quantity: 10,
    image: Glitters_300,
    description: 'Pure Silver Nail Glitter Powder High Gloss Nail Glitter Powder',
    category: 'Glitter Powder'
  },
  {
    id: 5,
    name: 'Glitters Green',
    price: 300,
    quantity: 10,
    image: Glitters_300_Green,
    description: 'High-quality, pigmented glitters. Make your nails look colorful and shiny. Can be used with Acrylic Powder, Dip Powder, Gel Builder, Gel Polish. Easy to apply and control.',
    category: 'Glitter Powder'
  },
  {
    id: 6,
    name: 'Glitters Mix',
    price: 300,
    quantity: 10,
    image: Glitters_300_mix,
    description: 'Starry Glass is a white holographic chunky glitter. Toasty can color shift from brown to gold.',
    category: 'Glitter Powder'
  },
  {
    id: 7,
    name: 'Super Glue',
    price: 1500,
    quantity: 10,
    image: Kolinsky_Brush_2500,
    description: 'Nail Gel 100Pcs 1g Mini Art Decor Glue Professional Acrylic Paste Nails Flase',
    category: 'Nail Gel'
  },
  {
    id: 8,
    name: 'Nails Buffer',
    price: 250,
    quantity: 10,
    image: Nails_Buffer_250,
    description: 'Simple Style Classic Style Letter Plastic NAIL FILE',
    category: 'Nail File'
  },
  {
    id: 9,
    name: 'Nails Cutter',
    price: 1500,
    quantity: 10,
    image: Nails_Cutter_1500,
    description: 'Nail tip cutter to help cut false nail tips quickly and straight',
    category: 'Nail Tools'
  },
  {
    id: 10,
    name: 'Nails File',
    price: 200,
    quantity: 10,
    image: Nails_File_200,
    description: 'Wooden Nail Files Bright Color Reusable Smooth Edge Clear Scale Multipurpose Trim Nails Lightweight Scale Ruler Half Moon Wooden Nail Files Polishing Tool Nail Supplies',
    category: 'Nail Tools'
  },
  {
    id: 11,
    name: 'Spider Gel',
    price: 1500,
    quantity: 10,
    image: Spider_Gel_1500,
    colors: ['Black', 'Yellow', 'Red', 'Blue', 'White'],
    description: 'Sparkle Professional Nail Art Spider Gel',
    category: 'Nail Gel'
  },
  {
    id: 12,
    name: 'Stone Picker',
    price: 700,
    quantity: 10,
    image: Stone_Picker_700,
    description: 'These wax pencils are the perfect tool for picking up flat back rhinestones, pearls, or 3D charms. This special pencil makes decorating your bling tumblers, phone cases, nails and more so much easier.',
    category: 'Nail Pencil'
  },
  {
    id: 13,
    name: 'Base Coat',
    price: 1400,
    quantity: 10,
    image: base_coat_1400,
    description: 'Proven to maintains shine after 2 weeks. Cures in 30 seconds. Fast application and simple removal.',
    category: 'Nail Polish'
  },
  {
    id: 14,
    name: 'Dotting Tools',
    price: 1500,
    quantity: 10,
    image: dotting_tools_1500,
    description: '5 Pack 2 Way Dotting Pen Tool Nail Art Tip Dot Paint Manicure kit Ceramic Pottery Embossing Tool Assorted One Size',
    category: 'Nail'
  },
  {
    id: 15,
    name: 'Reflective Chrome',
    price: 1500,
    quantity: 10,
    image: Reflective_chrome_1500,
    description: 'PrettyDiva Aurora Chrome Nail Powder - White Pearl Chrome Nail Powder with Aurora Color Shifting Metallic Powders Mirror Effect, Magic White Moonlight Effect Iridescent Pigment Powder for Nails',
    category: 'Nail'
  },
  {
    id: 16,
    name: 'Nails Stickers',
    price: 1500,
    quantity: 10,
    image: nails_stickers_1500,
    description: 'Nail Art Stickers - Designer Nail Decals - Butterfly Nail Stickers for Women - Nail Stickers for Kids - Butterfly Nail Decals',
    category: 'Nail'
  },
  {
    id: 17,
    name: 'Red Gel',
    price: 1400,
    quantity: 10,
    image: red_gel_1400,
    colors: ['Nude',' Red', 'Yellow', 'Orange', 'Pink', 'Blue', 'White'],
    description: 'Red Gel',
    category: 'Nail'
  },
  {
    id: 18,
    name: 'Acrylic Powder',
    price: 1000,
    quantity: 10,
    image: acrylic_powder_1000,
    colors: ['Nude',' White', 'Red', 'Yellow', 'Pink', 'Blue'],
    description: 'Acrylic Powder',
    category: 'Nail'
  },
  {
    id: 19,
    name: 'Cuticle Nipper',
    price: 1500,
    quantity: 10,
    image: cuticle_nipper_1500,
    description: 'Cuticle Nipper',
    category: 'Nail'
  },
  {
    id: 20,
    name: 'Stones',
    price: 1500,
    quantity: 10,
    image: stones_1500,
    description: 'Stones',
    category: 'Nail'
  },
  {
    id: 21,
    name: 'Toe Seperator',
    price: 2500,
    quantity: 10,
    image: toe_separator_2500,
    description: 'Toe Seperator',
    category: 'Toe'
  },
  {
    id: 22,
    name: 'Toe Nails',
    price: 3500,
    quantity: 10,
    image: toe_nails_3500,
    description: 'Toe Nails',
    category: 'Toe'
  },
  {
    id: 23,
    name: 'Kaws Charm',
    price: 3000,
    quantity: 10,
    image: kaws_charm_3000,
    description: 'Kaws Charm',
    category: 'Nails'
  },
  {
    id: 24,
    name: 'Nails Box',
    price: 15000,
    quantity: 10,
    image: nails_box_15000,
    description: 'Nails Box',
    category: 'Nails'
  },
  {
    id: 25,
    name: 'Top Coat',
    price: 1400,
    quantity: 10,
    image: top_coat_1400,
    description: 'Top Coat',
    category: 'Nails'
  },
  {
    id: 26,
    name: 'Snake Charm',
    price: 500,
    quantity: 10,
    image: snake_charm_500,
    description: 'Snake Charm',
    category: 'Charm'
  },
  {
    id: 27,
    name: 'Almond Nails',
    price: 4500,
    quantity: 10,
    image: almond_nails_4500,
    description: 'Almond Nails',
    category: 'Nail'
  },
  {
    id: 28,
    name: 'Stiletto Nails',
    price: 4500,
    quantity: 10,
    image: Stiletto_Nails_4500,
    description: 'Stiletto Nails',
    category: 'Nail'
  },
  {
    id: 29,
    name: 'Coffin Nails',
    price: 4500,
    quantity: 10,
    image: Coffin_Nails_4500,
    description: 'Coffin Nails',
    category: 'Nail'
  },
  {
    id: 30,
    name: 'Box Charm',
    price: 3000,
    quantity: 10,
    image: Box_Charm_3000,
    description: 'Box Charm',
    category: 'Charm'
  },
  {
    id: 31,
    name: 'Blooming Gel',
    price: 2000,
    quantity: 10,
    image: Blooming_Gel_2000,
    description: 'Blooming gel',
    category: 'Gel'
  },
  {
    id: 32,
    name: 'Cross Charm',
    price: 200,
    quantity: 10,
    image: Cross_Charm_200,
    description: 'Cross Charm',
    category: 'Charm'
  },
  {
    id: 33,
    name: 'Butterfly Charm',
    price: 200,
    quantity: 10,
    image: Butterfly_Charm_200,
    description: 'Butterfly Charm',
    category: 'Charm'
  },
  {
    id: 34,
    name: 'Teddy Bear',
    price: 167,
    quantity: 10,
    image: Teddy_Bear_167,
    description: 'TeddyBear',
    category: 'Charm'
  },
  {
    id: 35,
    name: 'Teddy Bear Charm',
    price: 200,
    quantity: 10,
    image: Teddybear_Charm_200,
    description: 'TeddyBear',
    category: 'Charm'
  },
  {
    id: 36,
    name: 'Kaws Charm',
    price: 200,
    quantity: 10,
    image: Kaws_Charm_200,
    description: 'Kaws Charm',
    category: 'Charm'
  },
  {
    id: 37,
    name: 'Long Square Nails',
    price: 400,
    quantity: 10,
    image: LongSquare_Nails_400,
    description: 'Long Square Nails',
    category: 'Nails'
  },
  {
    id: 38,
    name: 'Arm Rest',
    price: 6000,
    quantity: 10,
    image: Arm_Rest_6000,
    colors: ['Black', 'Yellow', 'Gold', 'Silver', 'Blue'],
    description: 'Arm Rest',
    category: 'Arm'
  },
  {
    id: 39,
    name: 'Arm Rest',
    price: 10000,
    quantity: 10,
    image: Arm_Rest_10000,
    description: 'Arm Rest',
    category: 'Arm'
  },
  {
    id: 40,
    name: 'Cardi B Nails',
    price: 400,
    quantity: 10,
    image: Cardib_Nails_400,
    description: 'Cardi B Nails',
    category: 'Nails',
  },
  {
    id: 41,
    name: 'Dissolver',
    price: 2500,
    quantity: 10,
    image: Dissolver_2500,
    description: 'Dissolver',
    category: 'Nails',
  },
  {
    id: 42,
    name: 'Practice Hand',
    price: 14000,
    quantity: 10,
    image: Practice_Hand_14000,
    description: 'Practice Hand',
    category: 'Nails',
  },
  {
    id: 43,
    name: 'Nails Tape',
    price: 1500,
    quantity: 10,
    image: Nails_Tape_1500,
    description: 'Nails tape',
    category: 'Nails',
  },
  {
    id: 44,
    name: 'ShortSquare Nails',
    price: 300,
    quantity: 10,
    image: ShortSquare_Nails_300,
    description: 'Short Square Nails',
    category: 'Nails',
  },
  {
    id: 45,
    name: 'Stone',
    price: 1500,
    quantity: 10,
    image: Stone_1500,
    description: 'Stone',
    category: 'Nails',
  },
  {
    id: 46,
    name: 'Flower Charm',
    price: 1500,
    quantity: 10,
    image: Flower_Charm_1500,
    description: 'Flower Charm',
    category: 'Charm',
  },
  {
    id: 47,
    name: 'LongSquare Nails Pack',
    price: 5000,
    quantity: 10,
    image: LongSquare_NailsPack_5000,
    description: 'Long Square Nails Pack',
    category: 'Nail',
  },
  {
    id: 48,
    name: 'Full Cover Square Tips',
    price: 5000,
    quantity: 10,
    image: FullCoverSquare_Tips_5000,
    description: 'Full Cover SquareTips',
    category: 'Nail',
  },
  {
    id: 49,
    name: 'Monomer Cup',
    price: 500,
    quantity: 10,
    image: Monomer_Cup_500,
    description: 'Monomer cup',
    category: 'Nail',
  },
  {
    id: 50,
    name: 'Nail Glue',
    price: 1500,
    quantity: 10,
    image: Nail_Stand_1500,
    description: 'Nail Glue',
    category: 'Nail',
  },
  {
    id: 51,
    name: 'Art Pen',
    price: 1500,
    quantity: 10,
    image: Art_Pen_1500,
    description: 'Art Pen',
    category: 'Nail',
  },
  {
    id: 52,
    name: '3D Flower Brush ',
    price: 1500,
    quantity: 10,
    image: ThreeD_FlowerBrush_1500,
    description: '3D Flower Brush',
    category: 'Brush',
  },
  {
    id: 53,
    name: 'Small Monomer',
    price: 1000,
    quantity: 10,
    image: Small_Monomer_1000,
    description: 'Small Monomer',
    category: 'Nails',
  },
  {
    id: 54,
    name: 'Clear Powder',
    price: 1000,
    quantity: 10,
    image: Clear_Powder_1000,
    description: 'Clear Powder',
    category: 'Nails',
  },
  {
    id: 55,
    name: 'Kolinsky Brushed',
    price: 3500,
    quantity: 10,
    image: Kolinsky_Brushed_3500,
    description: 'Kolinsky Brushed',
    category: 'Nails',
  },
  {
    id: 56,
    name: 'Half Tip Nails',
    price: 5500,
    quantity: 10,
    image: HalfTip_Nails_5500,
    description: 'Half Tip Nails',
    category: 'Nails',
  },
  {
    id: 57,
    name: 'Pigment',
    price: 1500,
    quantity: 10,
    image: Pigment_1500,
    description: 'Pigment',
    category: 'Nails',
  },
  {
    id: 58,
    name: 'Gold & Silver Liner',
    price: 1500,
    quantity: 10,
    colors: ['Gold', 'Silver'],
    image: Gold_Silver_Liner_1500,
    description: 'Gold & Silver Liner',
    category: 'Nails',
  },
  {
    id: 59,
    name: 'Medium Liquid',
    price: 7000,
    quantity: 10,
    image: Medium_Liquid_7000,
    description: 'Medium & Liquid',
    category: 'Nails',
  },
  {
    id: 60,
    name: 'Powder Brush',
    price: 2000,
    quantity: 10,
    image: Powder_Brush_1500,
    description: 'Powder brush',
    category: 'Brush',
  },
  {
    id: 61,
    name: 'Foil',
    price: 1800,
    quantity: 10,
    image: Foil_1800,
    description: 'Foil',
    category: 'Brush',
  },
  {
    id: 62,
    name: 'Acetone ',
    price: 3500,
    quantity: 10,
    image: Acetone_3500,
    description: 'Acetone',
    category: 'Nail',
  },
  {
    id: 63,
    name: 'Mixing Plate',
    price: 500,
    quantity: 10,
    image: Mixing_Plate_500,
    description: 'Mixing plate',
    category: 'Plate',
  },
  {
    id: 64,
    name: 'RhineStone Glue',
    price: 5000,
    quantity: 10,
    image: RhineStone_Glue_5000,
    description: 'RhineStone Glue',
    category: 'Glue',
  },
  {
    id: 65,
    name: 'Press On Glue',
    price: 1500,
    quantity: 10,
    image: PressOn_Glue_1500,
    description: 'Press On Glue',
    category: 'Glue',
  },
  {
    id: 66,
    name: 'Tack It',
    price: 2500,
    quantity: 10,
    image: Tack_It_2500,
    description: 'Tack It',
    category: 'Nails',
  },
  {
    id: 67,
    name: 'Solid Gel',
    price: 1500,
    quantity: 10,
    image: Solid_gel_1500,
    description: 'Solid Gel',
    category: 'Gel',
  },
  {
    id: 68,
    name: 'Air Brush',
    price: 23000,
    quantity: 10,
    image: Air_Brush_23000,
    description: 'Air Brush',
    category: 'Brush',
  },
  {
    id: 69,
    name: 'Dissolver Cup',
    price: 700,
    quantity: 10,
    image: Dissolver_Cup_700,
    description: 'Dissolver cup',
    category: 'Cup',
  },
  {
    id: 70,
    name: 'Stone Glue',
    price: 1500,
    quantity: 10,
    image: Stone_Glue_1500,
    description: 'Stone Glue',
    category: 'Glue',
  },
  {
    id: 71,
    name: 'Primer',
    price: 1500,
    quantity: 10,
    image: Primer_1500,
    description: 'Primer',
    category: 'Nails',
  },
  {
    id: 72,
    name: 'Practice Hand',
    price: 4000,
    quantity: 10,
    image: Practice_Hand_4000,
    description: 'Practice Hand',
    category: 'Nails',
  },
  {
    id: 73,
    name: 'Fur rug',
    price: 5000,
    quantity: 10,
    image: fur_rug_5000,
    description: 'Fur Rug',
    category: 'Rug',
  },
  {
    id: 74,
    name: 'Non rechargeable Drill',
    price: 13000,
    quantity: 10,
    image: nonrechargable_drill_13000,
    description: 'Nonrechargeable Drill',
    category: 'Nails',
  },
  {
    id: 75,
    name: 'Nails Magnet',
    price: 2000,
    quantity: 10,
    image: nails_magnet_2000,
    description: 'Nails Magnet',
    category: 'Nails',
  },
  {
    id: 76,
    name: 'Cuticle Remover',
    price: 150,
    quantity: 10,
    image: cuticle_remover_150,
    description: 'Cuticle Remover',
    category: 'Nails',
  },
  {
    id: 77,
    name: 'Cuticle Oil',
    price: 500,
    quantity: 10,
    image: cuticle_oil_500,
    description: 'Cuticle Oil',
    category: 'Nails',
  },
  {
    id: 78,
    name: 'Dotting Pen',
    price: 1500,
    quantity: 10,
    image: dotting_pen_1500,
    description: 'Dotting Pen',
    category: 'Nails',
  },
  {
    id: 79,
    name: 'Sugar',
    price: 500,
    quantity: 10,
    image: sugar_500,
    description: 'Sugar',
    category: 'Nails',
  },
  {
    id: 80,
    name: 'Non rechargeable UV Lamp',
    price: 13000,
    quantity: 10,
    image: nonrechargable_uvlamp_13000,
    description: 'Non reusable UV Lamp',
    category: 'Nails',
  },
  {
    id: 81,
    name: 'Rechargeable UV lamp',
    price: 26000,
    quantity: 10,
    image: Rechargeable_UVLamp_23000,
    description: 'Rechargeable UV lamp',
    category: 'Nails',
  },
  {
    id: 82,
    name: 'Acrylic Cup',
    price: 6000,
    quantity: 10,
    image: Acrylic_Cup_6000,
    description: 'Acrylic Cup',
    category: 'Nails',
  },
  {
    id: 83,
    name: 'Press On Glue',
    price: 150,
    quantity: 10,
    image: PressOn_Glue_150,
    description: 'Press On Glue',
    category: 'Glue',
  },
];

export default products;
