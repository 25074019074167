import React, { useState } from 'react';
import Navbar from './components/Navbar';
import ProductList from './components/ProductList';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ShoppingCart } from "./components/Pages/ShoppingCart";
import products from './productsData';
import './App.css';
import "../node_modules/slick-carousel/slick/slick.css"; 
import "../node_modules/slick-carousel/slick/slick-theme.css";
import DetailsPage from './components/DetailsPage';
import Footer from './components/Footer';
import ImageBanner from './components/ImageBanner';
import "././components/ProductList.css";

function App() {
  const [cart, setCart] = useState([]);
  const [showShippingDetails, setShowShippingDetails] = useState(false);

  const cartItemCount = cart.reduce((total, item) => total + item.quantity, 0);


  
  const addToCart = (productId, selectedColor) => {
    const productToAdd = products.find((item) => item.id === productId);
  
    if (!productToAdd) {
      console.error(`Product with id ${productId} not found.`);
      return;
    }
  
    if (productToAdd.colors && productToAdd.colors.length > 0) {
      // Product has color options
      const existingCartItem = cart.find(
        (item) => item.id === productId && item.selectedColor === selectedColor
      );
  
      if (existingCartItem) {
        // If the same product with the same color is already in the cart, increase the quantity
        const updatedCart = cart.map((item) =>
          item.id === productId && item.selectedColor === selectedColor
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
        setCart(updatedCart);
      } else {
        // If it's a new product or a product with a different color, add it to the cart
        setCart([...cart, { ...productToAdd, quantity: 1, selectedColor: [selectedColor] }]);
      }
    } else {
      // Product does not have color options
      const existingCartItem = cart.find((item) => item.id === productId);
  
      if (existingCartItem) {
        // If the same product is already in the cart, increase the quantity
        const updatedCart = cart.map((item) =>
          item.id === productId ? { ...item, quantity: item.quantity + 1 } : item
        );
        setCart(updatedCart);
      } else {
        // If it's a new product, add it to the cart without color details
        setCart([...cart, { ...productToAdd, quantity: 1 }]);
      }
    }
  };
  
  
  

  const removeFromCart = (productId, selectedColor) => {
    const updatedCart = cart
      .map((item) =>
        item.id === productId && item.selectedColor === selectedColor
          ? { ...item, quantity: item.quantity - 1 }
          : item
      )
      .filter((item) => item.quantity > 0);

    setCart(updatedCart);
  };

  const updateQuantity = (productId, newQuantity) => {
    const updatedCart = cart.map((item) =>
      item.id === productId ? { ...item, quantity: newQuantity } : item
    );

    setCart(updatedCart);
  };

  const getTotalPrice = (shippingInfo) => {
    // Calculate the total price including the shipping fee
    const totalProductPrice = cart.reduce((total, item) => total + item.price * item.quantity, 0);
    const totalWithShipping = shippingInfo ? totalProductPrice + shippingInfo.shippingFee : totalProductPrice;

    return totalWithShipping;
  };

  const getTotalQuantity = () => {
    return cart.reduce((total, item) => total + item.quantity, 0);
  };

  const checkout = (shippingInfo) => {

    const totalProductPrice = cart.reduce((total, item) => total + item.price * item.quantity, 0);
    const totalWithShipping = shippingInfo ? totalProductPrice + shippingInfo.shippingFee : totalProductPrice;
    // Merge shippingInfo with cart details
    const orderDetails = cart.map((item) => {
      const colorDetails = item.selectedColor ? ` - Color: ${item.selectedColor.join(', ')}` : '';
      return `${item.name} - Quantity: ${item.quantity} - Price: ₦${item.price * item.quantity}${colorDetails}`;
    });

    // Combine product details and shipping details
    const message = `
      New Order Details:
      ${orderDetails}
      
      Shipping Details:
      First Name: ${shippingInfo.firstName}
      Last Name: ${shippingInfo.lastName}
      Phone Number: ${shippingInfo.phoneNumber}
      Email Address: ${shippingInfo.emailAddress}
      Address: ${shippingInfo.address}
      City: ${shippingInfo.city}
      Shipping Fee: ₦${shippingInfo.shippingFee}
      
      Total: ₦${totalWithShipping}
    `;

    // Send the message to the seller via WhatsApp
    const sellerWhatsAppNumber = '+2349112136737'; // Replace with the actual number
    const encodedMessage = encodeURIComponent(message);

    // Open WhatsApp in a new window on user-initiated click
    const whatsappLink = `https://wa.me/${sellerWhatsAppNumber}?text=${encodedMessage}`;
    const newWindow = window.open(whatsappLink, '_blank');
    if (newWindow) {
      newWindow.focus();
    }
  };

  const sendWhatsAppMessage = async (shippingInfo) => {
    try {
      // Send order details to the server
      await fetch('http://localhost:8002/send-whatsapp-message', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userDetails: {
            firstName: shippingInfo.firstName,
            lastName: shippingInfo.lastName,
            phoneNumber: shippingInfo.phoneNumber,
            emailAddress: shippingInfo.emailAddress,
            address: shippingInfo.address,
            city: shippingInfo.city,
            shippingFee: shippingInfo.shippingFee,
          },
          orderItems: cart.map((item) => ({
            name: item.name,
            quantity: item.quantity,
            price: item.price,
            selectedColor: item.selectedColor || null,
          })),
          totalWithShipping: getTotalPrice(shippingInfo),
        }),
      });
  
      // Update the state or perform other actions if needed
    } catch (error) {
      console.error('Failed to send order details to the server', error);
    }
  };
  

  const handleProceed = (shippingInfo) => {
    // Perform any necessary actions with the shipping information
    console.log('Shipping Info:', shippingInfo);

    // Send WhatsApp message with user's details and order information
    sendWhatsAppMessage(shippingInfo);

    // For demonstration purposes, simulate the checkout after sending WhatsApp message
    // You can replace this with your actual checkout logic
    setShowShippingDetails(false);
    checkout(shippingInfo); // Pass the shippingInfo to the checkout function
  };

  return (
    <>
      <Router>
        <div className='app-container'> 
          <Navbar cartItemCount={cartItemCount} cart={cart} />
          <ImageBanner />
          <div className="App">
            <Routes>
              <Route path="/" element={<ProductList products={products} removeFromCart={removeFromCart} addToCart={addToCart} />} />
              <Route path="/cart" element={
                <ShoppingCart
                  cart={cart}
                  removeFromCart={removeFromCart}
                  addToCart={addToCart}
                  updateQuantity={updateQuantity}
                  getTotalQuantity={getTotalQuantity}
                  getTotalPrice={() => getTotalPrice(showShippingDetails)} 
                  checkout={checkout}
                  showShippingDetails={showShippingDetails}
                  setShowShippingDetails={setShowShippingDetails}
                  handleProceed={handleProceed}
                  sendWhatsAppMessage={sendWhatsAppMessage}
                />
              } />
              <Route path='/products/:id/*' element={<DetailsPage addToCart={addToCart} removeFromCart={removeFromCart} cart={cart} />} />
            </Routes>
            <Footer />
          </div>
        </div>
      </Router>
    </>
  );
}

export default App;
